import Section, { SectionProps } from '@/components/Section';

export default function TwoColumns({
  column1,
  column2,
  sectionProps,
}: {
  column1: JSX.Element;
  column2: JSX.Element;
  sectionProps: SectionProps;
}) {
  return (
    <Section
      {...sectionProps}
      className={`grid gap-8 lg:grid-cols-2 ${sectionProps.className}`}
    >
      {column1}
      {column2}
    </Section>
  );
}
