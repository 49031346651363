'use client';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import { Component } from 'react';

class ErrorBoundary extends Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="mx-auto mt-4 flex items-center justify-center gap-4 ">
          <NoSymbolIcon className="h-8 w-8 text-red-500" />
          <div className="flex flex-col">
            <p className="text-xl font-semibold">Loading error</p>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
