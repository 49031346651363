import Section, { SectionProps } from '@/components/Section';

export default function ThreeColumns({
  column1,
  column2,
  column3,
  sectionProps,
}: {
  column1: React.ReactNode;
  column2: React.ReactNode;
  column3: React.ReactNode;
  sectionProps: SectionProps;
}) {
  return (
    <Section {...sectionProps} className={`${sectionProps.className}`}>
      <div className="container grid gap-8 md:grid-cols-3">
        {column1}
        {column2}
        {column3}
      </div>
    </Section>
  );
}
