'use client';
import { motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

const ANIMATION_DURATION = 2;

export default function HomeHeroSubTitle() {
  const translation = useTranslations('HomeHero');
  const [currentIndex, setCurrentIndex] = useState(0);
  const subTitles = [translation('subTitle-1'), translation('subTitle-2')];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % subTitles.length);
    }, ANIMATION_DURATION * 1000);

    return () => clearInterval(interval);
  }, [subTitles.length]);

  const animation = {
    hidden: {
      opacity: 0,
      y: 30,
    },
    visible: {
      opacity: 1,
      y: 0,

      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
        duration: ANIMATION_DURATION,
      },
    },
    exit: {
      opacity: 0,
      y: -30,

      transition: {
        type: 'spring',
        damping: 12,
        stiffness: 100,
        duration: ANIMATION_DURATION,
      },
    },
  };
  return (
    <motion.h2 className="mb-8 flex gap-1 overflow-hidden text-balance text-2xl font-bold lg:text-3xl">
      {subTitles.map((subTitle, index) => (
        <motion.span
          key={index}
          initial="hidden"
          animate={currentIndex === index ? 'visible' : 'hidden'}
          exit="exit"
          variants={animation}
          className={currentIndex === index ? 'inline-block' : 'hidden'}
        >
          {subTitle}
        </motion.span>
      ))}
    </motion.h2>
  );
}
