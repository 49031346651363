'use client';
import ThreeColumns from '@/components/ThreeColumns';
import LiveCounter from '../LiveCounter';
import { SectionVariant } from '../Section';
import { useTranslations } from 'next-intl';

export default function ThreeColumnsCounter({
  reviewCount,
  bookingCount,
}: {
  reviewCount: number;
  bookingCount: number;
}) {
  const translation = useTranslations('ThreeColCounter');
  const column1 = (
    <div className="flex flex-col justify-center">
      <div className=" flex aspect-video h-auto w-full flex-col items-center justify-center rounded-lg ">
        <div className="flex aspect-video h-auto w-full flex-col items-center justify-center rounded-lg bg-blue-600 text-white ">
          <LiveCounter value={bookingCount} direction="up" />
          <div className="">
            <p className="text-lg">{translation('column-1.text')}</p>
          </div>
        </div>
      </div>
    </div>
  );

  const column2 = (
    <div className="flex flex-col justify-center">
      <div className=" flex aspect-video h-auto w-full flex-col items-center justify-center rounded-lg ">
        <div className="flex aspect-video h-auto w-full flex-col items-center justify-center rounded-lg bg-white text-blue-600 ">
          <h2 className="text-balance text-center text-4xl md:text-2xl lg:text-3xl xl:text-4xl">
            {translation('column-2.text-1')}
            <br />
            {translation('column-2.text-2')}
          </h2>
        </div>
      </div>
    </div>
  );

  const column3 = (
    <div className="flex flex-col justify-center">
      <div className=" flex aspect-video h-auto w-full flex-col items-center justify-center rounded-lg">
        <div className="flex aspect-video h-auto w-full flex-col items-center justify-center rounded-lg bg-blue-600 text-white ">
          <LiveCounter value={reviewCount} direction="up" />
          <div className="">
            <p className="text-lg">{translation('column-3.text')}</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <ThreeColumns
      column1={column1}
      column2={column2}
      column3={column3}
      sectionProps={{ variant: SectionVariant.White, fullWidth: true }}
    />
  );
}
