import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

interface Size {
  width: number;
  height: number;
}

export default function useElementSize<
  T extends HTMLElement = HTMLDivElement,
>(): [MutableRefObject<T | null>, Size] {
  const target = useRef<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const setFlooredSize = ({ width, height }: Size) => {
    setSize({ width: Math.floor(width), height: Math.floor(height) });
  };

  useLayoutEffect(() => {
    target.current && setFlooredSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
    setFlooredSize({ width, height });
  });

  return [target, size];
}
