export const API_URL = process.env.API_URL;

// @TODO: Replace the Tailwind constant with real Tailwind config
// @IDEA: Load the Tailwind "resolveConfig" with preval in the build step
export const TAILWIND_CONFIG = {
  screens: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
};
