'use client';
import Image from 'next/image';
import ThreeColumns from '@/components/ThreeColumns';
import { SectionVariant } from '../Section';

import { useEffect, useRef } from 'react';
import VideoPlayer from '@/components/VideoPlayer';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
export default function ThreeColumnsLPT() {
  const translation = useTranslations('ThreeColumnsLPT');
  const column1 = (
    <div className="flex flex-col justify-center">
      <VideoPlayer
        id="video-1"
        className="mb-4 aspect-video h-auto w-full cursor-default rounded-lg object-cover lg:mb-8"
        width={500}
        height={300}
        src="videos/jvkxbkpr9hnb2zkdtibo"
      />
      <div className="flex-1 ">
        <h3 className="text-lg font-bold lg:text-center">
          {translation('column-1.text')}
        </h3>
      </div>
    </div>
  );

  const column2 = (
    <div className="flex flex-col justify-center">
      <Link
        href="https://g.page/r/CYV2BpRsD0J2EBM"
        target="_blank"
        aria-label="Google Reviews"
        className="group mb-4 flex aspect-video h-auto w-full items-center justify-center rounded-lg bg-gray-50 lg:mb-8"
      >
        <Image
          src="/images/google-reviews.webp"
          alt={`Google Reviews logotype`}
          sizes="100vw"
          style={{
            width: '50%',
            height: 'auto',
          }}
          width={500}
          height={300}
          className="duration-50 aspect-video h-auto w-full rounded-lg object-contain object-center transition ease-in-out group-hover:scale-90"
        />
      </Link>
      <div className="flex-1 ">
        <h3 className="text-lg font-bold lg:text-center">
          {translation('column-2.text')}
        </h3>
      </div>
    </div>
  );

  const column3 = (
    <div className="flex flex-col justify-center">
      <VideoPlayer
        id="video-2"
        className="mb-4 aspect-video h-auto w-full cursor-default rounded-lg object-cover lg:mb-8"
        width={500}
        height={300}
        src="videos/sd7ijqv74ebkpweclzdo"
      />
      <div className="flex-1 ">
        <h3 className="text-lg font-bold lg:text-center">
          {translation('column-3.text')}
        </h3>
      </div>
    </div>
  );

  return (
    <ThreeColumns
      column1={column1}
      column2={column2}
      column3={column3}
      sectionProps={{ variant: SectionVariant.White, fullWidth: true }}
    />
  );
}
