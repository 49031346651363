'use client';

import Image from 'next/image';
import { LazyMotion, domAnimation, m } from 'framer-motion';
import TwoColumns from '@/components/TwoColumns';
import Button, { ButtonVariant } from '@/components/Button';
import {
  HomeDocumentDataJoinhairdresserItem,
  Simplify,
} from '@/../prismicio-types';
import { isFilled } from '@prismicio/client';
import { PrismicRichText } from '@prismicio/react';
import { CldImage } from 'next-cloudinary';

export default function TwoColumnsHairdresser({
  title,
  text,
  buttontext,
  buttonlink,
}: Simplify<HomeDocumentDataJoinhairdresserItem>) {
  const column1 = (
    <div className="relative aspect-square h-auto w-full rounded-lg bg-white object-cover">
      <CldImage
        src="images/wjejbdol7cs4unh1vgv7"
        alt="Hairdresser"
        sizes="800px"
        fill
        className="aspect-square h-auto w-full rounded-lg object-cover"
      />
    </div>
  );

  const column2 = (
    <div className="group relative overflow-hidden rounded-lg bg-white transition-shadow duration-300 hover:shadow-2xl">
      <div className="z-10 flex w-full flex-col justify-center gap-4 p-8 md:absolute md:h-full lg:gap-8 lg:px-16 lg:py-0 xl:px-32">
        {isFilled.keyText(title) && <h2>{title}</h2>}
        {isFilled.richText(text) && <PrismicRichText field={text} />}
        {isFilled.keyText(buttontext) && isFilled.link(buttonlink) && (
          <Button prismicField={buttonlink} variant={ButtonVariant.Primary}>
            {buttontext}
          </Button>
        )}
      </div>
      <div className="absolute inset-0">
        <LazyMotion features={domAnimation}>
          <m.div
            initial={false}
            whileInView={{ x: '16rem' }}
            viewport={{ once: true }}
            className="absolute -left-full -top-3/4 hidden h-full w-full translate-x-0 rounded-full bg-blue-600 transition-transform ease-out md:block xl:-top-2/3"
          />
          <m.div
            initial={false}
            whileInView={{ x: '-6rem' }}
            viewport={{ once: true }}
            className="absolute -bottom-64 -right-96 hidden h-96 w-96 -translate-x-0 rounded-full bg-blue-600 transition-transform ease-out md:block xl:-bottom-32"
          />
        </LazyMotion>
      </div>
    </div>
  );

  return <TwoColumns column1={column1} column2={column2} sectionProps={{}} />;
}
