'use client';

import { LazyMotion, domAnimation, m } from 'framer-motion';
import Button, { ButtonVariant } from '@/components/Button';

import {
  Simplify,
  HomeDocumentDataTwocolumntrustItem,
} from '@/../prismicio-types';
import { GroupField, isFilled } from '@prismicio/client';
import { PrismicRichText } from '@prismicio/react';
export default function TrustLeftColumn({
  title,
  text,
  buttontext,
  buttonlink,
}: Simplify<HomeDocumentDataTwocolumntrustItem>) {
  return (
    <div className="group relative overflow-hidden rounded-lg bg-white transition-shadow duration-300 hover:shadow-2xl">
      <div className="z-10 flex w-full flex-col justify-center gap-4 p-8 md:absolute md:h-full lg:gap-8 lg:px-16 lg:py-0 xl:px-32">
        {isFilled.keyText(title) && <h2>{title}</h2>}
        {isFilled.richText(text) && <PrismicRichText field={text} />}
        {isFilled.keyText(buttontext) && isFilled.link(buttonlink) && (
          <Button prismicField={buttonlink} variant={ButtonVariant.Primary}>
            {buttontext}
          </Button>
        )}
      </div>
      <div className="absolute inset-0">
        <LazyMotion features={domAnimation}>
          <m.div
            initial={false}
            whileInView={{ x: '-16rem' }}
            viewport={{ once: true }}
            className="absolute -right-full -top-2/3 hidden h-full w-full -translate-x-0 rounded-full bg-blue-600 transition-transform ease-out md:block xl:-top-1/2"
          />
          <m.div
            initial={false}
            whileInView={{ x: '6rem' }}
            viewport={{ once: true }}
            className="absolute -bottom-64 -left-96 hidden h-96 w-96 translate-x-0 rounded-full bg-blue-600 transition-transform ease-out md:block xl:-bottom-32"
          />
        </LazyMotion>
      </div>
    </div>
  );
}
