'use client';

import { useEffect, useState } from 'react';
import { AnimatePresence, LazyMotion, domAnimation, m } from 'framer-motion';
import Image from 'next/image';
import useElementSize from '@/hooks/useElementSize';
import useBreakpoint from '@/hooks/useBreakpoint';

const CARD_WIDTH = 128;
const CARD_WIDTH_CLASS = `w-[128px]`; // We need this to prevent tree shaking
const CARD_MODIFIER = 20;

const degrees = [
  '-20deg',
  '-18deg',
  '-16deg',
  '-14deg',
  '-12deg',
  '-10deg',
  '-8deg',
  '-6deg',
  '-4deg',
  '-2deg',
  '2deg',
  '4deg',
  '6deg',
  '8deg',
  '10deg',
  '12deg',
  '14deg',
  '16deg',
  '18deg',
  '20deg',
];

const randomDegree = () => {
  const index = Math.floor(Math.random() * degrees.length);
  return degrees[index];
};

type Position = {
  x: string;
  y: string;
  rotate: string;
  opacity: number;
};

export default function HaircutCards({ cards = [] }: { cards: string[] }) {
  const isSm = useBreakpoint('sm');
  const isMd = useBreakpoint('md');
  const isLg = useBreakpoint('lg');
  const isXl = useBreakpoint('xl');

  const [containerRef, { width }] = useElementSize();
  const [currentCards, setCurrentCards] = useState<any[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (cards.length === 0 || width <= 0) return;

    let cardsCount = 1;

    if (isXl) {
      cardsCount = 13;
    } else if (isLg) {
      cardsCount = 10;
    } else if (isMd) {
      cardsCount = 10;
    } else if (isSm) {
      cardsCount = 12;
    } else {
      cardsCount = 10;
    }

    const generatePositions = (): Position[] => {
      const containerRadius = Math.floor(width / 2);
      const cardRadius = Math.floor(CARD_WIDTH / 2);
      const innerRadius = containerRadius - cardRadius;
      const positions: Position[] = [];

      for (
        let angle = 0;
        angle < 2 * Math.PI;
        angle += (2 * Math.PI) / cardsCount
      ) {
        let modifierX = Math.floor(Math.random() * (CARD_MODIFIER + 1) + 5);
        let modifierY = Math.floor(Math.random() * (CARD_MODIFIER + 1) + 5);
        modifierX *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;
        modifierY *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;

        const x =
          containerRadius -
          cardRadius +
          innerRadius * Math.cos(angle) +
          modifierX;

        const y =
          containerRadius -
          cardRadius +
          innerRadius * Math.sin(angle) +
          modifierY;

        positions.push({
          x: `${Math.round(x)}px`,
          y: `${Math.round(y)}px`,
          rotate: randomDegree(),
          opacity: 1,
        });
      }

      return positions;
    };

    const updateCardPositions = () => {
      const positions: Position[] = generatePositions();
      setPositions(positions);
      setCurrentCards(cards.slice(0, cardsCount));
    };

    updateCardPositions();
  }, [cards, isSm, isMd, isLg, isXl, width]);

  return (
    <div
      ref={containerRef}
      className="relative mx-auto aspect-square h-auto w-full items-center justify-center md:max-lg:w-2/3"
    >
      <LazyMotion features={domAnimation}>
        <AnimatePresence>
          {currentCards.map((card, index) => {
            const hideVariant: Position = {
              x: `${Math.floor(width / 2 - CARD_WIDTH / 2)}px`,
              y: `${Math.floor(width / 2 - CARD_WIDTH / 2)}px`,
              rotate: '0deg',
              opacity: 0,
            };
            const showVariant = positions[index];

            return (
              <m.div
                key={index}
                className={`absolute aspect-square ${CARD_WIDTH_CLASS} will-change-opacity overflow-hidden rounded-lg bg-white p-2 shadow-sm will-change-transform`}
                initial={hideVariant}
                whileInView={{
                  ...showVariant,
                  transition: {
                    delay: isMounted ? 0 : Math.random() * (0.5 - 0) + 0,
                  },
                }}
                exit={hideVariant}
                onAnimationStart={() => setIsMounted(true)}
                viewport={{ once: true }}
              >
                <div className="relative flex h-full w-full items-center justify-center rounded-lg bg-gray-50 font-bold">
                  <Image
                    src={card}
                    alt={`Image of a haircut ${index}`}
                    fill
                    sizes={`${CARD_WIDTH}px`}
                    className="h-auto w-full rounded-lg object-cover object-center"
                  />
                </div>
              </m.div>
            );
          })}
        </AnimatePresence>
      </LazyMotion>
    </div>
  );
}
