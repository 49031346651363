'use client';

import { useEffect, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import useBreakpoint from '@/hooks/useBreakpoint';
import { GroupField } from '@prismicio/client';
import {
  Simplify,
  HomeDocumentDataTestimonialsItem,
} from '@/../prismicio-types';

const TestimonialSlider = ({
  testimonials,
}: {
  testimonials: GroupField<Simplify<HomeDocumentDataTestimonialsItem>>;
}) => {
  const isLg = useBreakpoint('lg');
  const [showSlider, setShowSlider] = useState(false);

  const sliderSettings: Settings = {
    className: 'center',
    centerMode: false,
    infinite: false,
    centerPadding: '32px',
    slidesToShow: isLg ? 3 : 1,
    speed: 300,
    dots: true,
    arrows: false,
  };

  useEffect(() => {
    setShowSlider(true);
  }, []);

  if (!showSlider) return null;

  return (
    <div className="container pb-8 lg:pb-16">
      <Slider {...sliderSettings}>
        {testimonials.map((testimonial, index) => {
          return (
            <div key={index} className="px-4">
              <div className="flex w-full flex-col items-center justify-center gap-2 rounded-lg bg-white p-4 lg:p-8">
                <p className="max-w-[75ch] flex-1 text-sm">
                  {testimonial.text}
                </p>
                <p className="font-bold">{testimonial.name}</p>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
export default TestimonialSlider;
