'use client';
import Button, { ButtonVariant } from '@/components/Button';
import { isFilled } from '@prismicio/client';
import { PrismicRichText } from '@prismicio/react';
import {
  HomeDocumentDataJoinpartnerItem,
  Simplify,
} from '@/../prismicio-types';

export default function CallToActionPartner({
  title,
  text,
  buttontext,
  buttonlink,
}: Simplify<HomeDocumentDataJoinpartnerItem>) {
  return (
    <div className="bg-blue-600 py-8 text-center text-white lg:py-16">
      <div className="container flex flex-col items-center gap-4">
        {isFilled.keyText(title) && <h2>{title}</h2>}
        {isFilled.richText(text) && (
          <div className="mt-1 max-w-[75ch] text-center">
            <PrismicRichText field={text} />
          </div>
        )}
        {isFilled.keyText(buttontext) && isFilled.link(buttonlink) && (
          <Button
            prismicField={buttonlink}
            className="mt-2"
            variant={ButtonVariant.White}
          >
            {buttontext}
          </Button>
        )}
      </div>
    </div>
  );
}
