import { CSSProperties, DetailedHTMLProps, HTMLAttributes } from 'react';
export enum SectionVariant {
  Primary = 'PRIMARY',
  Light = 'LIGHT',
  White = 'WHITE',
  Dark = 'DARK',
  Default = 'DEFAULT',
}
export type SectionProps = {
  children?: React.ReactNode;
  fullWidth?: boolean;
  noSpacing?: boolean;
  className?: string;
  style?: CSSProperties;
  variant?: SectionVariant;
} & DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

export default function Section({
  children,
  fullWidth,
  noSpacing,
  className,
  style,
  variant,
  ...rest
}: SectionProps) {
  let variantClassName = '';
  switch (variant) {
    case SectionVariant.Primary:
      variantClassName = 'bg-blue-600 text-white';
      break;
    case SectionVariant.Light:
      variantClassName = 'bg-gray-50';
      break;
    case SectionVariant.White:
      variantClassName = 'bg-white';
      break;
    case SectionVariant.Dark:
      variantClassName = 'bg-gray-800 text-white';
      break;
    default:
      variantClassName = '';
  }
  return (
    <section
      style={style}
      className={` ${noSpacing ? '' : 'py-8 lg:py-16'} ${variantClassName}  `}
      {...rest}
    >
      <div
        className={`${fullWidth ? '' : 'container'} ${className ? className : ''}`}
      >
        {children}
      </div>
    </section>
  );
}
