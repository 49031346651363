'use client';

import { CldVideoPlayer, CldVideoPlayerProps } from 'next-cloudinary';
import 'next-cloudinary/dist/cld-video-player.css';

export default function VideoPlayer(
  props: CldVideoPlayerProps
): React.JSX.Element {
  return (
    <CldVideoPlayer
      {...props}
      controls={false}
      muted={true}
      autoplay={true}
      loop={true}
      fluid={true}
      hideContextMenu={true}
      playsinline={true}
      analytics={false}
      allowUsageReport={false}
    />
  );
}
