'use client';

import { forwardRef, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/sv';
import { useRouter } from 'next/navigation';
import { sendGTMEvent } from '@next/third-parties/google';

import { MapPinIcon } from '@heroicons/react/24/outline';
import Button, { ButtonVariant } from '@/components/Button';
import { useTranslations } from 'next-intl';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { sv } from 'date-fns/locale/sv';
registerLocale('sv', sv);
setDefaultLocale('sv');

export type HomeHeroFormProps = {
  cities: string[];
  locale: string;
};

export default function HomeHeroForm({ cities, locale }: HomeHeroFormProps) {
  const translation = useTranslations('HomeHero');
  const router = useRouter();
  const [selectedCity, setSelectedCity] = useState<string | undefined>(
    undefined
  );
  const [selectedDate, setSelectedDate] = useState<string | undefined>(
    undefined
  );

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCity(event.target.value);
  };

  const handleDateChange = (value: Date) => {
    setSelectedDate(value ? dayjs(value).format('YYYY-MM-DD') : undefined);
  };

  const handleBookClick = () => {
    const params = new URLSearchParams();

    if (selectedCity) {
      params.set('city', selectedCity);
    } else {
      params.delete('city');
    }

    if (selectedDate) {
      params.set('date', selectedDate);
    } else {
      params.delete('date');
    }

    sendGTMEvent({
      event: 'bookClicked',
      city: selectedCity,
      date: selectedDate,
    });

    router.push(`/boka?${params.toString()}`);
  };

  const DatePickerButton = forwardRef((props: any, ref: any) => (
    <button
      type="button"
      className="h-full w-full text-left"
      onClick={props.onClick}
      ref={ref}
    >
      {props.value ? props.value : 'Snarast'}
    </button>
  ));
  DatePickerButton.displayName = 'DatePickerButton';

  return (
    <div className="flex flex-col gap-4 rounded-[2.5rem] bg-white p-4 text-black lg:flex-row lg:rounded-full lg:p-4">
      <div className="flex flex-1 flex-col gap-4 lg:flex-row">
        <div className="flex-none lg:flex-1">
          <div className="relative flex h-14 items-center justify-center rounded-full bg-gray-50 px-4 transition-all duration-300 focus-within:bg-gray-50">
            <select
              onChange={handleCityChange}
              className="block h-full w-full cursor-pointer appearance-none bg-transparent focus:outline-none"
            >
              <option value="">{translation('Form.City.default')}</option>
              {cities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
            <button className="absolute right-4">
              <MapPinIcon className="h-6 w-6 hover:text-blue-600" />
            </button>
          </div>
        </div>
        <div className="flex-none lg:flex-1">
          <div className="flex h-14 items-center justify-center rounded-full bg-gray-50 px-4 transition-all duration-300 focus-within:bg-gray-50">
            <DatePicker
              dateFormat={'YYYY-MM-DD'}
              minDate={new Date()}
              selected={dayjs(selectedDate).toDate() || undefined}
              value={selectedDate || ''}
              onChange={handleDateChange}
              isClearable={!!selectedDate}
              customInput={<DatePickerButton />}
              popperPlacement="bottom-start"
            />
          </div>
        </div>
      </div>
      <Button
        onClick={handleBookClick}
        variant={ButtonVariant.Primary}
        className="w-full lg:w-auto"
      >
        {translation('Form.Button.text')}
      </Button>
    </div>
  );
}
