'use client';

import { useRef, useEffect, useState } from 'react';
import {
  useMotionValue,
  useSpring,
  useInView,
  useTransform,
  motion,
} from 'framer-motion';
export default function LiveCounter({
  value,
  direction = 'up',
}: {
  value: number;
  direction?: 'up' | 'down';
}) {
  const ref = useRef<HTMLHeadingElement>(null);

  const motionValue = useMotionValue(direction === 'down' ? value : 0);

  const springValue = useSpring(motionValue, {
    damping: 80,
    stiffness: 80,
  });
  const textSizeTranform = useTransform(springValue, [0, value], [1, 1.2]);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      motionValue.set(direction === 'down' ? 0 : value);
    }
  }, [motionValue, isInView, direction, value]);

  useEffect(
    () =>
      springValue.on('change', (latest: any) => {
        if (ref.current) {
          ref.current.textContent = latest.toFixed(0);
        }
      }),
    [springValue]
  );

  return (
    <motion.div className="" style={{ scale: textSizeTranform }}>
      <h2 ref={ref} className=" flex-1 text-3xl">
        0
      </h2>
    </motion.div>
  );
}
