import { useMediaQuery } from 'react-responsive';
import { TAILWIND_CONFIG } from '@/utils/constants';

const breakpoints = TAILWIND_CONFIG.screens;

type BreakpointKey = keyof typeof breakpoints;

export default function useBreakpoint<K extends BreakpointKey>(
  breakpointKey: K
) {
  const bool = useMediaQuery({
    query: `(min-width: ${breakpoints[breakpointKey]})`,
  });

  return bool;
}
