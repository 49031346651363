import { LinkField } from '@prismicio/client';
import { PrismicNextLink } from '@prismicio/next';
import Link from 'next/link';

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  variant?: ButtonVariant;
  href?: string;
  prismicField?: LinkField;
  children: React.ReactNode;
}

export enum ButtonVariant {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  White = 'WHITE',
  Ghost = 'GHOST',
}

export default function Button({
  variant,
  href,
  children,
  prismicField,
  ...rest
}: ButtonProps) {
  const baseClasses =
    'inline-flex h-14 items-center justify-center rounded-full px-4 text-base font-medium tracking-wider transition duration-300 ease-in-out lg:px-8';
  let classes =
    'bg-blue-600 hover:bg-blue-700 disabled:bg-blue-600/60 text-white';

  if (variant === ButtonVariant.Secondary) {
    classes = 'bg-black hover:bg-gray-700 disabled:bg-black/60 text-white';
  }

  if (variant === ButtonVariant.White) {
    classes = 'bg-white hover:bg-gray-100 disabled:bg-gray-100 text-black';
  }

  if (variant === ButtonVariant.Ghost) {
    classes = 'bg-transparent text-black disabled:text-gray-500';
  }

  if (prismicField) {
    return (
      <PrismicNextLink
        field={prismicField}
        className={`${baseClasses} ${classes} ${rest.className}`}
      >
        {' '}
        {children}
      </PrismicNextLink>
    );
  }
  if (href) {
    return (
      <Link
        href={href}
        className={`${baseClasses} ${classes} ${rest.className}`}
      >
        {children}
      </Link>
    );
  }

  return (
    <button
      {...rest}
      type={rest.type || 'button'}
      className={`${baseClasses} ${classes} ${rest.className} btn`}
    >
      {children}
    </button>
  );
}
